import React, { useState, useEffect, useRef } from "react";

export const useScrollToDown = () => {
  const [bannerHeight, SetBannerHeight] = useState();
  const bannerContentRef = useRef(null);

  useEffect(() => {
    SetBannerHeight(bannerContentRef.current.clientHeight);
  }, []);
  const scrollDownClick = () => {
    window.scrollTo(0, bannerHeight - 100);
  };

  return {
    scrollDownClick,
    bannerHeight,
    bannerContentRef,
  };
};
