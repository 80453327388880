import { useEffect } from "react";
import dynamic from "next/dynamic";
import LazyLoad from "react-lazyload";
import { useDispatch } from "react-redux";

const BackgroundBanner = dynamic(() => import("@/common/BackgroundBanner"));
const InfoSection = dynamic(() => import("@/components/InfoSection"));
const ProductSection = dynamic(() => import("@/components/ProductSection"));
const MapSection = dynamic(() => import("@/components/MapSection"));
const BottomInfoSection = dynamic(() =>
  import("@/components/BottomInfoSection")
);
const GalleryView = dynamic(() => import("@/components/GalleryView"));
const MetaTags = dynamic(() => import("@/common/MetaTags"));

import { useScrollToDown } from "@/hooks/scrollToDown";

// Import redux actions
import store from "@/redux/store";
import { fetchHomePagesData } from "@/redux/actions/homePageActions";
import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";

// Import graphQL query variable
import { HOME_PAGE_QUERY } from "@/graphql/homePageQuery";
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";

export default function Home({ homePageData, labelsConfigData }) {
  const { bannerContentRef, scrollDownClick } = useScrollToDown();
  const dispatch = useDispatch();

  useEffect(() => {
    // Set labelslist local state while changed on redux store data
    dispatch(setLabelsConfigsData(labelsConfigData));
  }, [dispatch, labelsConfigData]);

  return (
    <>
      <MetaTags
        seoData={homePageData?._seoMetaTags}
        noFollow={homePageData?.nofollow}
        noIndex={homePageData?.noindex}
      />
      <section
        ref={bannerContentRef}
        className="relative h-[100vh] w-full z-10"
      >
        {/* Main banner with background Image and Video */}
        <BackgroundBanner
          bannerTitle={homePageData?.subtitle}
          scrollDownClick={() => scrollDownClick()}
          isHomePage={true}
          singleMedia={homePageData?.singleMedia}
        />
      </section>
      <section className="px-[30px]">
        {homePageData?.introSection?.length > 0 &&
          homePageData?.introSection?.map((item, index) => {
            return (
              <InfoSection
                key={index}
                title={item?.title}
                abstract={item?.abstract}
                linksToPages={item?.linksToPages}
              />
            );
          })}
      </section>
      <LazyLoad offset={100}>
        <section className="bg-white px-[30px]">
          {homePageData?.ourProductsSection?.length > 0 &&
            homePageData?.ourProductsSection?.map((item, index) => {
              return (
                <ProductSection
                  key={index}
                  mainTitle={item?.title}
                  desc={item?.abstract}
                  singleWines={item?.singleWines}
                  linksToPages={item?.linksToPages}
                />
              );
            })}
        </section>
      </LazyLoad>
      <section>
        <div className="px-[30px]">
          {homePageData?.vineyardsSection?.length > 0 &&
            homePageData?.vineyardsSection?.map((item, index) => {
              return <BottomInfoSection vineyardsData={item} key={index} />;
            })}
        </div>
        <LazyLoad offset={100}>
          <MapSection
            title={homePageData?.whereWeAreTitle}
            address={homePageData?.address}
            mapData={homePageData?.map}
            officeDescription={homePageData?.officeDescription}
            officeMapData={homePageData?.officeMap}
          />
        </LazyLoad>
      </section>
      {/* <LazyLoad offset={100}>
        <section className="bg-white px-[30px]">
          <GalleryView />
        </section>
      </LazyLoad> */}
    </>
  );
}

export async function getStaticProps({ preview = false, locale = "it" }) {
  const homeResponse = await store.dispatch(
    fetchHomePagesData(HOME_PAGE_QUERY, preview, { lang: locale })
  );

  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, { lang: locale })
  );

  return {
    props: {
      preview,
      homePageData: homeResponse?.home || {},
      labelsConfigData: labelsConfigData || {},
    },
  };
}
